import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DataService } from "@shared/async-services/data.service";
import { Observable } from "rxjs";
import * as CryptoJS from "crypto-js";
import { ListTable } from "@shared/models";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductType } from "@shared/utility/enum";
import { InsuranceService } from "./insurance.service";

@Injectable({
  providedIn: "root",
})
export class RedirectToPolicyDashboardService {
  public addOnCRMBasePath = "CRM/";
  public addOnOperationBasePath = "operation/";
  public documentBasePath = "Document/";
  public activityBasePath = "Activity/";
  public addOnInsuranceBasePath = "Insurance/";
  public addOnConfigurationBasePath = "configuration/";
  public DocumentGenerationBasePath = "DocumentGeneration/";
  public ReportBasePath = "report/";
  constructor(
    protected httpClient: HttpClient,
    protected dataService: DataService,
    private router: Router,
    private insuranceService: InsuranceService,
    private activatedRoute: ActivatedRoute
  ) {}

  reDirectDashboard(policyId: any) {
    let encryptedqueryparams: { policyId: number };
    encryptedqueryparams = {
      policyId: policyId,
    };
    this.dataService
      .get(
        this.addOnInsuranceBasePath +
          "Quote/GetProductDetailsByPolicyId/" +
          policyId
      )
      .subscribe(
        (response: any) => {
          var dataItem = response;
          if (dataItem != null && dataItem != undefined && dataItem != "") {
            if (dataItem.productId === ProductType.ELPL) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product/policy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (
              dataItem.productId === ProductType.Lei ||
              dataItem.productId === ProductType.Fcc ||
              dataItem.productId === ProductType.Shop ||
              dataItem.productId === ProductType.Off
            ) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product/ccpolicy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Car) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product/car-policy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Sb) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product/sb-policy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Rei) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product/reipolicy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Pi) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product-tm/pi-policy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Pa) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(["/product-tm/pa-policy-dashboard"], {
                  queryParams: {
                    data: this.insuranceService.encryptData(
                      encryptedqueryparams
                    ),
                  },
                  relativeTo: this.activatedRoute.root,
                })
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Bbg) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(
                  ["/product-tm/bbg-policy-dashboard"],
                  {
                    queryParams: {
                      data: this.insuranceService.encryptData(
                        encryptedqueryparams
                      ),
                    },
                    relativeTo: this.activatedRoute.root,
                  }
                )
              );
              window.open(url, "_blank");
            } else if (dataItem.productId === ProductType.Poi) {
              const url = this.router.serializeUrl(
                this.router.createUrlTree(
                  ["/property-owner/poi-policy-dashboard"],
                  {
                    queryParams: {
                      data: this.insuranceService.encryptData(
                        encryptedqueryparams
                      ),
                    },
                    relativeTo: this.activatedRoute.root,
                  }
                )
              );
              window.open(url, "_blank");
            }
          }
        },
        (error) => {
          // Handle any errors here
          console.error("Error fetching perils list:", error);
        }
      );
  }
}
