import { Component, Input, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { transform } from '@progress/kendo-drawing/dist/npm/geometry';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { getExactDate, getExactDateWithoutTime, getExactDateWithoutTimeWithFormate } from '@shared/utility';
import { ToasterService } from '@shared/utility/toaster.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '@app/auth/auth.service';
import { Subscription } from 'rxjs';
import { RedirectToPolicyDashboardService } from '@app/services/redirect.dashboard.service';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrl: './transaction-detail.component.css'
})
export class TransactionDetailComponent {
  transactionDetail: any;
  @Input() transactionId: any;
  @Input() proposer:any;
  @Input() policyNo: any;
  transactionForm: FormGroup;
  isEdit = false;
  isSystemAgent = false;
  subscription = new Subscription();
  canEditTransaction = false;
  isupdated = false;
  constructor(public datepipe: DatePipe, private fb: FormBuilder, private insuranceService: InsuranceService, private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService, private confirMationDialogService: ConfirmationDialogService,
    private authservice: AuthService,
    @Optional() private activeModal: NgbActiveModal,
    private router: Router
    ,private redirectToPolicyDashboardService: RedirectToPolicyDashboardService) { }

  ngOnInit(): void {
    var userDetail = JSON.parse(window.sessionStorage.getItem('authentication'));
    this.isSystemAgent = userDetail.isSystemAgent;
    this.canEditTransaction = this.authservice.getClaims('CANVIEWTRANSACTIONSUMMARY');
    this.inititForm();
    if (this.transactionId) {
         if(this.isSystemAgent){
          this.getTransactionDetail();
         }
         else{
          this.GetAccountTransactionDetailAgent();
         }
    }
  }

  redirectToPolicyDashboard(policyId){
    this.redirectToPolicyDashboardService.reDirectDashboard(policyId);
  }


  openPaymentInfo(paymentId:any){
    let encryptedqueryparams: { acPaymentId: number, backBtnText: any, backBtnPath: any };
encryptedqueryparams = { acPaymentId: paymentId, backBtnText: "<<<< Reconciliation Page", backBtnPath: "/report/creditor-reconciliation" };
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/payment-info"], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams),
        },
        relativeTo: this.activatedRoute.root,
      })
    );
    window.open(url, '_blank');
  }

  EditData() {
    this.isEdit = true;
    // let date = this.transactionDetail.policyInceptionDate;
    // if (!this.transactionDetail.policyInceptionDate) {
    //   date = new Date();
    // }
    // else {
    //   date = date.replace(/-/gi, "/");

    // }
    this.transactionForm.controls['inceptionDate'].patchValue(getExactDateWithoutTime(this.transactionDetail.policyInceptionDate));
    this.transactionForm.controls['transactionDate'].patchValue(getExactDateWithoutTime(this.transactionDetail.transactionDate));
    this.transactionForm.controls['amount'].patchValue(this.transactionDetail.premium);
    this.transactionForm.controls['note'].patchValue(this.transactionDetail.policyNote);
  }


  getTransactionDetail() {
    this.subscription.add(
      this.insuranceService.GetAccountTransactionDetail(this.transactionId).subscribe((data: any): void => {
        if (data != null) {
          this.transactionDetail = data;
          if (this.transactionDetail != null && this.transactionDetail != undefined) {
            this.transactionDetail.inceptionDate = (this.transactionDetail.transactionInceptionDate != null && this.transactionDetail.transactionInceptionDate != undefined) ? this.transactionDetail.transactionInceptionDate : this.transactionDetail.policyInceptionDate
          }
        }
      }));
  }

  GetAccountTransactionDetailAgent() {
    this.subscription.add(
      this.insuranceService.GetAccountTransactionDetailAgent(this.transactionId).subscribe((data: any): void => {
        if (data != null) {
          this.transactionDetail = data;
          // if (this.transactionDetail != null && this.transactionDetail != undefined) {
          //   this.transactionDetail.inceptionDate = (this.transactionDetail.transactionInceptionDate != null && this.transactionDetail.transactionInceptionDate != undefined) ? this.transactionDetail.transactionInceptionDate : this.transactionDetail.policyInceptionDate
          // }
        }
      }));
  }

  Save() {
    if (this.transactionForm.valid) {
      const objClaimSave = Object.assign({});
      objClaimSave.acTransactionId = this.transactionDetail.acTransactionId;
      objClaimSave.premium = this.transactionForm.controls['amount']?.value;
      objClaimSave.transactionDate = getExactDate(this.transactionForm.controls['transactionDate']?.value);
      objClaimSave.inceptionDate = getExactDate(this.transactionForm.controls['inceptionDate']?.value);
      objClaimSave.note = this.transactionForm.controls['note']?.value;
      this.insuranceService.UpdateTransaction(objClaimSave).subscribe((data: any): void => {
        if (data.success) {
          this.transactionDetail.policyInceptionDate = this.transactionForm.controls['inceptionDate']?.value
          this.transactionDetail.transactionDate = this.transactionForm.controls['transactionDate']?.value;
          this.transactionDetail.premium = this.transactionForm.controls['amount']?.value;
          this.transactionDetail.policyNote = this.transactionForm.controls['note']?.value;
          this.transactionDetail.brokerCommRatePer = data.payload.brokerCommRate && data.payload.brokerCommRate > 0 ? data.payload.brokerCommRate * 100 : 0;
          this.transactionDetail.brokerAmount = data.payload.brokerComm;
          this.toasterService.success("Transaction update success.")
          this.isEdit = false;
          this.isupdated = true;
        }
      });
    }
    else {
      this.insuranceService.validateAllFormFields(this.transactionForm)
    }
  }

  Cancel() {
    this.transactionForm.reset();
    this.isEdit = false;
  }

  closeDialog() {
    if (this.isEdit) {
      // this.confirMationDialogService
      //   .confirm(
      //     "Unsaved Chagnes",
      //     "There are some unsaved chagnes, do you wanted to discard and close?",
      //     "Yes",
      //     "No"
      //   )
      //   .then((r) => {
      //     if (r) {
      if (confirm("There are some unsaved chagnes, do you wanted to discard and close?"))
        this.activeModal.close(this.isupdated);
      // }
      // });
    }
    else
      this.activeModal.close(this.isupdated);
  }

  inititForm() {
    this.transactionForm = this.fb.group({
      amount: [null, Validators.required],
      transactionDate: [null, Validators.required],
      inceptionDate: [null, Validators.required],
      note: [null],
    });
  }

  openBankDetail(id: any) {
    let encryptedqueryparams: {accontType: any,bankId:number};
    encryptedqueryparams = {accontType:'CL',bankId : id}
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["report/bank-payment-detail"], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams),
        },
        relativeTo: this.activatedRoute.root,
      })
    );
    window.open(url, '_blank');
  }

}
