<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0">Transaction Details
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <form novalidate [formGroup]="transactionForm">
        <div class="form-row justify-content-end mb-3 d-flex">
            <div class="col-md-auto col-auto me-2" *ngIf="this.isEdit==false&&canEditTransaction==true">
                <button class="btn btn-sm btn-primary" (click)="EditData()"> <span
                        class="material-symbols-outlined fs-20">
                        edit
                    </span></button>
            </div>
            <div class="col-md-auto col-auto  me-2" *ngIf="this.isEdit==true&&canEditTransaction==true">
                <button class="btn btn-sm btn-primary" (click)="Save()">
                    <span class="material-symbols-outlined cursor-pointer">
                        check
                    </span>
                </button>
            </div>
            <button class="btn btn-danger btn-sm me-2" (click)="Cancel()"
                *ngIf="this.isEdit==true&&canEditTransaction==true">
                <span class="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </button>
        </div>
            <div class="npd-container trans-container ">
                <div class="policy-box accordion"  id="transactionaccordionExample" *ngIf="isSystemAgent==false">
                    <h6 class="accordion-header data-header text-white mb-0" id="transactionAgentheadingOne">
                        <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#transactionAgentCollapseOne"
                            aria-expanded="true" aria-controls="transactionAgentCollapseOne">
                            Transaction Number is : {{transactionDetail?.acTransactionId}}
                        </button>
                    </h6>
                    <div id="transactionAgentCollapseOne" class="accordion-collapse  bg-white collapse show pt-3"  aria-labelledby="transactionAgentheadingOne"
                data-bs-parent="#transactionaccordionExample">
                <div class="row input-without-lbl-gray">
                    <div class="col-md-6">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Transaction Date :</label>
                            <label class="font-weight-semibold"> {{
                                transactionDetail?.transactionDate | date:
                                "dd/MM/yyyy"
                                }}</label>
                            <!-- <div class="position-relative" *ngIf="this.isEdit==true">
                                <kendo-datepicker formControlName="transactionDate" class=""
                                    [format]="'dd/MM/yyyy'"></kendo-datepicker>
                                <div class="validation-msg" *ngIf="
                                        transactionForm.controls?.transactionDate.touched &&
                                        transactionForm.controls?.transactionDate.errors?.required">
                                    Required
                                </div>
                            </div> -->
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Broker Code :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.brokerCode}}</label>
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Inception Date</label>
                            <label class="font-weight-semibold">{{transactionDetail?.inceptionDate | date:
                                "dd/MM/yyyy"}}
                            </label>
                            <!-- <div class="position-relative" *ngIf="this.isEdit==true">
                            <kendo-datepicker formControlName="inceptionDate"
                                [format]="'dd/MM/yyyy'"></kendo-datepicker>
                            <div class="validation-msg" *ngIf="
                                transactionForm.controls?.inceptionDate.touched &&
                                transactionForm.controls?.inceptionDate.errors?.required">
                                Required
                            </div>
                        </div> -->
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Proposer :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.policyProposer}}</label>
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Notes :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.policyNote}}</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Amount</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.premium | currency:'
                                ':'symbol':'0.2-2'}}</label>
                            <!-- <div class="position-relative" *ngIf="this.isEdit==true">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="/assets/images/euro-currency-symbol.svg" alt="Euro">
                                    </span>
                                </div>
                                <input type="text" class="form-control" twodecimalOnly formControlName="amount"
                                    maxlength="8" min="1" max="99999999">
                            </div>
                            <div class="validation-msg" *ngIf="
                            transactionForm.controls?.amount.touched &&
                            transactionForm.controls?.amount.errors?.required">
                                Required
                            </div>
                        </div> -->
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Broker Commission Rate</label>
                            <label class="font-weight-semibold">{{transactionDetail?.brokerCommRate}}%</label>
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Broker Commission Amount :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.brokerComm | currency:'
                                ':'symbol':'0.2-2'}}</label>
                        </div>
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Levy :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.levy}}%</label>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        <div class="policy-box accordion"  id="transactionSummaryAccordionExample" *ngIf="isSystemAgent==true">
            <h6 class="accordion-header data-header text-white mb-0" id="transactionSummaryHeadingOne">
                <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#transactionSummaryCollapseOne"
                    aria-expanded="true" aria-controls="transactionSummaryCollapseOne">
                    Transaction Summary for Transaction #{{transactionDetail?.acTransactionId}}
                </button>
            </h6>
            <div id="transactionSummaryCollapseOne" class="accordion-collapse  bg-white collapse show pt-3"  aria-labelledby="transactionSummaryHeadingOne"
        data-bs-parent="#transactionSummaryAccordionExample">
            <div class="row input-without-lbl-gray">
                <div class="col-md-6">
                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Transaction Date :</label>
                        <label *ngIf="this.isEdit==false" class="font-weight-semibold"> {{
                            transactionDetail?.transactionDate | date: "dd/MM/yyyy"
                            }}</label>
                        <div class="position-relative" *ngIf="this.isEdit==true">
                            <kendo-datepicker formControlName="transactionDate" class=""
                                [format]="'dd/MM/yyyy'"></kendo-datepicker>
                            <div class="validation-msg" *ngIf="
                                    transactionForm.controls?.transactionDate.touched &&
                                    transactionForm.controls?.transactionDate.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>


                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Description :</label>
                        <label class="font-weight-semibold">{{transactionDetail?.transDescription}}({{transactionDetail?.transDescriptionId}})</label>
                    </div>


                    <div class="gray-input-box d-flex justify-content-between">
                        <label> Amount :</label>
                        <label class="font-weight-semibold" *ngIf="this.isEdit==false|| (transactionDetail?.paid ?? 0) !== 0">€{{transactionDetail?.premium
                            | currency:' ':'symbol':'0.2-2'}}</label>
                        <div class="position-relative" *ngIf="this.isEdit==true && (transactionDetail?.paid ?? 0) === 0">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="/assets/images/euro-currency-symbol.svg" alt="Euro">
                                    </span>
                                </div>
                                <input type="text" class="form-control" negativetwodecimalOnly formControlName="amount"
                                    maxlength="8" min="1" max="99999999">
                            </div>
                            <div class="validation-msg" *ngIf="
                    transactionForm.controls?.amount.touched &&
                    transactionForm.controls?.amount.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Premium (Incl. 5% Levy) :</label>
                        <label class="font-weight-semibold">€{{transactionDetail?.premium-transactionDetail?.fees |
                            currency:' ':'symbol':'0.2-2'}} <span  *permission="'CANVIEWANNUALMTAPREMIUM'"> <span *ngIf="transactionDetail?.transType== 'MTA'">(Annual : € {{transactionDetail?.annualisedMtaPremium |
                                currency:' ':'symbol':'0.2-2'}})</span></span> </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Created By/On :</label>
                        <label class="font-weight-semibold">{{transactionDetail?.createdByName}} - {{
                            transactionDetail?.createdDate | date: "dd/MM/yyyy HH:mm:ss" }}</label>
                    </div>
                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Type :</label>
                        <label class="font-weight-semibold">{{transactionDetail?.transType}}</label>
                    </div>
                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Broker :</label>
                        <label class="font-weight-semibold">{{transactionDetail?.brokerName}} -
                            {{transactionDetail?.brokerCode}}</label>
                    </div>
                    <div class="gray-input-box d-flex justify-content-between">
                        <label>Fees :</label>
                        <label class="font-weight-semibold">€{{transactionDetail?.fees | currency:'
                            ':'symbol':'0.2-2'}}</label>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="mt-3 accordion"  id="policyDetailsAccordionExample" *ngIf="isSystemAgent==true">
            <div class="policy-box">
                <h6 class="accordion-header data-header text-white mb-0" id="policyDetailsHeadingOne">
                    <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#policyDetailsCollapseOne"
                        aria-expanded="true" aria-controls="policyDetailsCollapseOne">
                        Policy Details
                    </button>
                </h6>
                <div id="policyDetailsCollapseOne" class="accordion-collapse  bg-white collapse show pt-3" aria-labelledby="policyDetailsHeadingOne"
                data-bs-parent="#policyDetailsAccordionExample">
                <div class="row input-without-lbl-gray">
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Policy Number :</label>
                            <label class="font-weight-semibold">
                                        {{transactionDetail?.policyNumber ?? policyNo}}
                                    </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Inception Date :</label>
                            <label *ngIf="this.isEdit==false"
                                class="font-weight-semibold">{{transactionDetail?.inceptionDate | date:
                                "dd/MM/yyyy"}}</label>
                            <div class="position-relative" *ngIf="this.isEdit==true">
                                <kendo-datepicker formControlName="inceptionDate" class=""
                                    [format]="'dd/MM/yyyy'"></kendo-datepicker>
                                <div class="validation-msg" *ngIf="
                                transactionForm.controls?.inceptionDate.touched &&
                                transactionForm.controls?.inceptionDate.errors?.required">
                                    Required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Proposer :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.proposer ?? proposer}}</label>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <div class="gray-input-box d-flex justify-content-between position-relative">
                            <label class="pe-5">Optis Note:</label>
                            <label class="font-weight-semibold"
                                *ngIf="this.isEdit==false">{{transactionDetail?.policyNote}}</label>
                            <textarea *ngIf="this.isEdit==true" name="" rows="3" id="" formControlName="note"
                                maxlength="500" class="form-control form-control-textarea"
                                appSpaceInputRestriction></textarea>
                        </div>
                    </div> -->
                </div>
            </div>
            </div>
        </div>
        <div class="mt-3 accordion"  id="commisionDetailsAccordionExample" *ngIf="isSystemAgent==true">
            <div class="policy-box">
                <h6 class="accordion-header data-header text-white mb-0" id="commisionDetailsHeadingOne">
                    <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#commisionDetailsCollapseOne"
                        aria-expanded="true" aria-controls="commisionDetailsCollapseOne">
                        Commision Details
                    </button>
                </h6>
                <div id="commisionDetailsCollapseOne" class="accordion-collapse  bg-white collapse show pt-3"  aria-labelledby="commisionDetailsHeadingOne"
                data-bs-parent="#commisionDetailsAccordionExample">
                <div class="row input-without-lbl-gray">
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Broker :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.brokerComm | currency:'
                                ':'symbol':'0.2-2'}}({{transactionDetail?.brokerCommRate}}%)</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Broker Fees :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.brokerCommFees??0 | currency:'
                                ':'symbol':'0.2-2'}}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Optis :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.optisComm | currency:'
                                ':'symbol':'0.2-2'}}({{transactionDetail?.optisCommRate}}%)</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Sub Broker :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.subBrokerComm | currency:'
                                ':'symbol':'0.2-2'}}({{transactionDetail?.subBrokerCommrate}}%)</label>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="transactionDetail?.lloydsComm||transactionDetail?.lloydsCommRate">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Llyods :</label>
                            <label class="font-weight-semibold">€{{transactionDetail?.lloydsComm??0 | currency:'
                                ':'symbol':'0.2-2'}}({{transactionDetail?.lloydsCommRate}}%)</label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="mt-3 accordion"  id="paymentDetailsAccordionExample" *ngIf="isSystemAgent==true">
            <div class="policy-box">
                <h6 class="accordion-header data-header text-white mb-0" id="paymentDetailsHeadingOne">
                    <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#paymentDetailsCollapseOne"
                        aria-expanded="true" aria-controls="paymentDetailsCollapseOne">
                        Payment Details
                    </button>
                </h6>
                <div id="paymentDetailsCollapseOne" class="accordion-collapse  bg-white collapse show pt-3"  aria-labelledby="paymentDetailsHeadingOne"
                data-bs-parent="#paymentDetailsAccordionExample">
                <div  *ngIf="!transactionDetail?.brokerPaymentNo && !transactionDetail?.insurerPaymentNo && !transactionDetail?.subBrokerPaymentNo && !transactionDetail?.transferPaymentNo">
                    <div class="row input-without-lbl-gray">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>
                                No Payments exists for this Transaction
                            </label>
                        </div>
                    </div>
                </div>
                <div class="trans-tbl" *ngIf="transactionDetail?.brokerPaymentNo || transactionDetail?.insurerPaymentNo || transactionDetail?.subBrokerPaymentNo || transactionDetail?.transferPaymentNo">
                    <div class="inner-tbl">
                        <table cellpadding="0" cellspacing="0" border="0">
                            <thead>
                                <th valign="middle" width="10%">&nbsp;</th>
                                <th valign="middle" width="10%">Cashbook # / Amt</th>
                                <th valign="middle" width="15%">Cashbook Date</th>
                                <th valign="middle" width="15%">Amount Paid</th>
                                <th valign="middle" width="10%">WriteOff</th>
                                <th valign="middle" width="10%">Bank #</th>
                                <th valign="middle" width="15%">Bank Date</th>
                                <th valign="middle" width="15%">Bank Amount</th>
                            </thead>
                            <tr *ngIf="transactionDetail?.brokerPaymentNo">
                                <td valign="middle" width="10%"><strong>Broker</strong></td>
                                <td valign="middle" width="10%">
                                    <label class=" cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.brokerPaymentNo && transactionDetail?.brokerPaymentNo!='N/A'&& transactionDetail?.brokerPaymentNo!=null">
                                        <a
                                            (click)="openPaymentInfo(transactionDetail?.brokerPaymentNo)">{{transactionDetail?.brokerPaymentNo}}</a>
                                    </label>
                                    <label
                                        *ngIf="!transactionDetail?.brokerPaymentNo">N/A</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.brokerPaymentDate">
                                        {{transactionDetail?.brokerPaymentDate | date:"dd/MM/yyyy"}}
                                    </label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label>
                                        €{{transactionDetail?.paid??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.writeOff??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label class="cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.brokerBankId">
                                        <a
                                            (click)="openBankDetail(transactionDetail?.brokerBankId)">{{transactionDetail?.brokerBankId}}</a>
                                    </label>
                                    <label *ngIf="!transactionDetail?.brokerBankId">-</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.brokerBankDate">
                                        {{transactionDetail?.brokerBankDate | date:"dd/MM/yyyy"}}
                                    </label>
                                    <label *ngIf="!transactionDetail?.brokerBankDate">-</label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.brokerBankAmount ??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                            </tr>
                            <tr *ngIf="transactionDetail?.insurerPaymentNo">
                                <td valign="middle" width="10%"><strong>Company</strong></td>
                                <td valign="middle" width="10%">
                                    <label class=" cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.insurerPaymentNo && transactionDetail?.insurerPaymentNo!='N/A'">
                                        <a
                                            (click)="openPaymentInfo(transactionDetail?.insurerPaymentNo)">{{transactionDetail?.insurerPaymentNo}}</a>
                                    </label>
                                    <label
                                        *ngIf="!transactionDetail?.insurerPaymentNo || transactionDetail?.insurerPaymentNo=='N/A'">N/A</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.insurerPaymentDate">
                                        {{transactionDetail?.insurerPaymentDate | date:"dd/MM/yyyy"}}
                                    </label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label>
                                        €{{transactionDetail?.paidInsurer??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.companyWriteOff??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                        <label class="cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.insurerBankId">
                                        <a
                                            (click)="openBankDetail(transactionDetail?.insurerBankId)">{{transactionDetail?.insurerBankId}}</a>
                                    </label>

                                    <label *ngIf="!transactionDetail?.insurerBankId">-</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.insurerBankDate">
                                        {{transactionDetail?.insurerBankDate | date:"dd/MM/yyyy"}}
                                    </label>
                                    <label *ngIf="!transactionDetail?.insurerBankDate">-</label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.insurerBankAmount ??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                            </tr>
                            <tr *ngIf="transactionDetail?.subBrokerPaymentNo">
                                <td valign="middle" width="10%"><strong>Sub Broker</strong></td>
                                <td valign="middle" width="10%">
                                    <label class=" cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.subBrokerPaymentNo && transactionDetail?.subBrokerPaymentNo!='N/A'">
                                        <a
                                            (click)="openPaymentInfo(transactionDetail?.subBrokerPaymentNo)">{{transactionDetail?.subBrokerPaymentNo}}</a>
                                    </label>
                                    <label
                                        *ngIf="!transactionDetail?.subBrokerPaymentNo || transactionDetail?.subBrokerPaymentNo=='N/A'">N/A</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.subBrokerPaymentDate">
                                        {{transactionDetail?.subBrokerPaymentDate | date:"dd/MM/yyyy"}}
                                    </label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label>
                                        €{{transactionDetail?.paidSubBroker??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        -
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                        <label class="cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.subBrokerBankId">
                                        <a
                                            (click)="openBankDetail(transactionDetail?.subBrokerBankId)">{{transactionDetail?.subBrokerBankId}}</a>
                                    </label>

                                    <label *ngIf="!transactionDetail?.subBrokerBankId">-</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.subBrokerBankDate">
                                        {{transactionDetail?.subBrokerBankDate | date:"dd/MM/yyyy"}}
                                    </label>
                                    <label *ngIf="!transactionDetail?.subBrokerBankDate">-</label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.subBrokerBankAmount ??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                            </tr>
                            <tr *ngIf="transactionDetail?.transferPaymentNo && isSystemAgent==true ">
                                <td valign="middle" width="10%"><strong>Office Transfer</strong></td>
                                <td valign="middle" width="10%">
                                    <label class=" cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.transferPaymentNo && transactionDetail?.transferPaymentNo!='N/A'">
                                        <a
                                            (click)="openPaymentInfo(transactionDetail?.transferPaymentNo)">{{transactionDetail?.transferPaymentNo}}</a>
                                    </label>
                                    <label
                                        *ngIf="!transactionDetail?.transferPaymentNo || transactionDetail?.transferPaymentNo=='N/A'">N/A</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.transferPaymentDate">
                                        {{transactionDetail?.transferPaymentDate | date:"dd/MM/yyyy"}}
                                    </label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label>
                                        €{{transactionDetail?.transferPaymentAmount??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        -
                                    </label>
                                </td>
                                <td valign="middle" width="10%">
                                        <label class="cursor-pointer text-primary"
                                        *ngIf="transactionDetail?.transferBankId">
                                        <a
                                            (click)="openBankDetail(transactionDetail?.transferBankId)">{{transactionDetail?.transferBankId}}</a>
                                    </label>
                                    <label *ngIf="!transactionDetail?.transferBankId">-</label>
                                </td>
                                <td valign="middle" width="15%">
                                    <label *ngIf="transactionDetail?.transferBankDate">
                                        {{transactionDetail?.transferBankDate | date:"dd/MM/yyyy"}}
                                    </label>
                                <label class="font-weight-semibold cursor-pointer"
                                    *ngIf="!transactionDetail?.transferBankDate">N/A</label>
                                </td>
                                <td valign="middle" width="10%">
                                    <label>
                                        €{{transactionDetail?.transferBankAmount ??0 | currency:' ':'symbol':'0.2-2'}}
                                    </label>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="mt-3 accordion"  id="commisionDetailsAccordionExample" *ngIf="isSystemAgent==true">
            <div class="policy-box">
                <h6 class="accordion-header data-header text-white mb-0" id="commisionDetailsHeadingOne">
                    <button class="accordion-button bg-transparent p-0 mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#commisionDetailsCollapseOne"
                        aria-expanded="true" aria-controls="commisionDetailsCollapseOne">
                        Notes
                    </button>
                </h6>
                <div id="commisionDetailsCollapseOne" class="accordion-collapse  bg-white collapse show pt-3"  aria-labelledby="commisionDetailsHeadingOne"
                data-bs-parent="#commisionDetailsAccordionExample">
                <div class="row input-without-lbl-gray">
                    <div class="col-md" *ngIf="transactionDetail?.policyNote != null">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Optis Notes :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.policyNote}}</label>
                        </div>
                    </div>
                    <!-- <div class="col-md" *ngIf="transactionDetail?.optisNote != null || transactionDetail?.optisNote == ' ' ">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Optis Note :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.optisNote}}</label>
                        </div>
                    </div>
                    <div class="col-md" *ngIf="transactionDetail?.adminNotes != null">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Admin Note :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.adminNotes}}</label>
                        </div>
                    </div>
                    <div class="col-md" *ngIf="transactionDetail?.optisNotes2 != null">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Optis Additional Notes :</label>
                            <label class="font-weight-semibold">{{transactionDetail?.optisNotes2}}</label>
                        </div>
                    </div> -->
                </div>
            </div>
            </div>
        </div>

</div>
    </form>
</div>
<div class="modal-footer">
    <div class="form-row justify-content-end">
        <div class="col-md-auto col-auto">
            <button class="btn btn-sm btn-primary" (click)="closeDialog()">Close</button>
        </div>
    </div>
</div>